<!-- eslint-disable tailwindcss/no-custom-classname -->
<template>
  <div
    class="relative"
    :class="props.containerClass"
    @mouseleave="hover ? deactivate() : undefined"
  >
    <ClientOnly>
      <Teleport to="body">
        <div
          ref="tooltipRef"
          class="tooltip left-0 top-0 z-tooltip w-max"
          :class="[
            props.strategy === 'fixed' ? 'fixed' : 'absolute',
            { 'tooltip--modal': displayAsModal },
            roundedClass,
          ]"
          :style="`--arrow-size: ${ARROW_SIZE}px`"
          @mouseenter="hover && isSm ? activate() : undefined"
          @mouseleave="hover ? deactivate() : undefined"
        >
          <div
            v-if="tooltipOffset && tooltipVisible"
            :style="{ '--tooltip-offset': `${tooltipOffset}px` }"
            data-offset-fill
          />

          <div
            class="border border-blue-100 px-4 py-2 text-sm text-blue shadow-popover"
            :class="[tooltipClass, roundedClass]"
          >
            <slot />
          </div>

          <div
            v-if="showArrow"
            ref="arrowRef"
            class="arrow"
          />
        </div>
      </Teleport>
    </ClientOnly>

    <UiModal
      v-if="displayAsModal"
      size="tooltip"
      :open="modalVisible"
      @close="modalVisible = false"
    >
      <div
        class="mr-14 pt-2 sm:mr-0 sm:w-auto sm:p-0"
        :class="[props.modalTooltipClasses]"
      >
        <slot />
      </div>
    </UiModal>

    <div
      ref="triggerRef"
      :class="triggerWrapperClass"
      v-bind="$attrs"
      @mouseenter="hover && isSm ? activate() : undefined"
      @click="hover && !isSm ? activate() : undefined"
    >
      <slot
        name="trigger"
        :activate="activate"
        :deactivate="deactivate"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  arrow,
  autoUpdate,
  computePosition,
  flip,
  hide,
  offset,
} from '@floating-ui/dom'
import { computed, inject, nextTick, onMounted, onUnmounted, ref, watch } from 'vue'
import UiModal from '../UiModal/UiModal.vue'
import { RawlplugUiMqKey } from '../../plugin'
import type { UiPopoverTooltipProps } from './UiPopoverTooltip.types'

const props = withDefaults(defineProps<UiPopoverTooltipProps>(), {
  showArrow: true,
  offset: 0,
  placement: 'top',
  strategy: 'absolute',
  flip: true,
  hide: false,
  mobileModal: true,
  containerClass: () => [],
  tooltipClass: () => [],
  triggerWrapperClass: 'w-fit',
  minWidthAsParent: false,
  rounded: 'lg',
})

const emit = defineEmits<{
  (e: 'activated'): void
  (e: 'deactivated'): void
}>()

defineSlots<{
  default: () => any
  trigger: (props: { activate: () => void, deactivate: () => void }) => any
}>()

const roundedClass = computed(() => `rounded-${props.rounded}`)

const ARROW_SIZE = 8 // px

const tooltipRef = ref<HTMLDivElement>()
const triggerRef = ref<HTMLDivElement>()
const arrowRef = ref<HTMLDivElement>()

const isMounted = ref(false)
const floatingUiInstance = ref(null)

const { isSm } = inject(RawlplugUiMqKey) || {}

const tooltipVisible = ref(false)
watch([tooltipVisible, tooltipRef], () => {
  if (!tooltipRef.value) { return }

  const isVisible = tooltipVisible.value

  if (isVisible) {
    tooltipRef.value.setAttribute('data-show', 'true')
  }
  else {
    tooltipRef.value.removeAttribute('data-show')
  }
})

const modalVisible = ref(false)
const displayAsModal = computed(() => {
  return !isSm?.value && props.mobileModal
})

watch(displayAsModal, () => {
  modalVisible.value = false
})

function activate() {
  if (displayAsModal.value) {
    modalVisible.value = true
  }
  else {
    tooltipVisible.value = true
  }
  emit('activated')
}

function deactivate() {
  tooltipVisible.value = false
  modalVisible.value = false
  emit('deactivated')
}

const tooltipOffset = computed(() => {
  if (props.showArrow) {
    return ARROW_SIZE
  }

  return typeof props.offset === 'number' ? props.offset : 0
})

watch([isMounted, triggerRef, tooltipRef], () => {
  if (floatingUiInstance.value) { return }
  if (!isMounted.value || !triggerRef.value || !tooltipRef.value) { return }

  attachFloatingUiInstance()
})

function attachFloatingUiInstance() {
  floatingUiInstance.value = autoUpdate(
    triggerRef.value,
    tooltipRef.value,
    () => {
      const middleware = [offset(tooltipOffset.value)]

      if (props.flip) {
        middleware.push(flip())
      }

      if (props.hide) {
        middleware.push(hide())
      }

      if (arrowRef.value) {
        middleware.push(
          arrow({
            element: arrowRef.value,
          }),
        )
      }

      computePosition(triggerRef.value, tooltipRef.value, {
        placement: props.placement,
        middleware,
        strategy: props.strategy,
      }).then(({ x, y, middlewareData, placement }) => {
        Object.assign(tooltipRef.value.style, {
          left: `${x}px`,
          top: `${y}px`,
          ...(props.minWidthAsParent && {
            minWidth: `${triggerRef.value.clientWidth}px`,
          }),
        })

        tooltipRef.value.setAttribute('data-popover-placement', placement)

        if (middlewareData.arrow && arrowRef.value?.style) {
          const { x, y } = middlewareData.arrow
          Object.assign(arrowRef.value.style, {
            left: x != null ? `${x}px` : '',
            top: y != null ? `${y}px` : '',
          })
        }

        if (props.hide && middlewareData.hide) {
          Object.assign(tooltipRef.value.style, {
            visibility: middlewareData.hide.referenceHidden
              ? 'hidden'
              : 'visible',
          })
        }
      })
    },
  )
}

onMounted(async () => {
  await nextTick()
  isMounted.value = true
})

onUnmounted(() => {
  if (floatingUiInstance.value) {
    // cleanup function returned from autoUpdate
    floatingUiInstance.value()
  }
})

defineExpose({
  tooltipRef,
  activate,
  deactivate,
})
</script>

<style lang="postcss" scoped>
.tooltip {
  display: none;
  background: white;

  &[data-show] {
    display: block;
  }

  .arrow,
  .arrow::before {
    position: absolute;
    width: var(--arrow-size);
    height: var(--arrow-size);
    background: inherit;
  }

  .arrow {
    visibility: hidden;
    border-color: inherit;
  }

  .arrow::before {
    visibility: visible;
    content: '';
    transform: rotate(45deg);
    @apply border border-blue-100;
    background: inherit;
    z-index: 1;
  }

  &--modal {
    display: none;
  }

  > [data-offset-fill] {
    --tooltip-offset: 0px;
    position: absolute;
  }

  &[data-popover-placement^='top'] > [data-offset-fill] {
    height: var(--tooltip-offset);
    @apply bottom-0 translate-y-full w-full;
  }

  &[data-popover-placement^='bottom'] > [data-offset-fill] {
    height: var(--tooltip-offset);
    @apply top-0 -translate-y-full w-full;
  }

  &[data-popover-placement^='left'] > [data-offset-fill] {
    width: var(--tooltip-offset);
    @apply right-0 translate-x-full h-full;
  }

  &[data-popover-placement^='right'] > [data-offset-fill] {
    width: var(--tooltip-offset);
    @apply left-0 -translate-x-full h-full;
  }
}

.tooltip[data-popover-placement^='top'] > .arrow {
  bottom: calc(var(--arrow-size) / -2);

  &:before {
    border-top-color: transparent;
    border-left-color: transparent;
  }
}
.tooltip[data-popover-placement^='bottom'] > .arrow {
  top: calc(var(--arrow-size) / -2);

  &:before {
    border-bottom-color: transparent;
    border-right-color: transparent;
  }
}
.tooltip[data-popover-placement^='left'] > .arrow {
  right: calc(var(--arrow-size) / -2);

  &:before {
    border-bottom-color: transparent;
    border-left-color: transparent;
  }
}
.tooltip[data-popover-placement^='right'] > .arrow {
  left: calc(var(--arrow-size) / -2);

  &:before {
    border-top-color: transparent;
    border-right-color: transparent;
  }
}
</style>
